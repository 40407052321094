@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@layer base {
  :root {
    --color-black: 9 14 14; /* 090E0E */
    --color-yellow: 255 215 0; /* FFD700 */ 
    --color-dark-grey: 70 79 77; /* 464E4D */
    --color-white: 255 255 255; /* FFFFFF */
    --color-light-grey: 240 240 240; /* F0F0F0 */
  }
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #464E4D;
}

h1{
  @apply py-3 text-center text-4xl font-bold text-black
}

h2{
  @apply py-3 text-left text-2xl font-bold text-black
}

h3{
  @apply py-1 text-lg font-bold text-black
}

.grid-container{
  @apply grid grid-cols-4 md:grid-cols-12 px-3 md:px-12
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
  background-size: 0 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size .5s ease-in-out;
}

.link-underline-yellow {
  background-image: linear-gradient(transparent, transparent), linear-gradient(#FFD700, #FFD700)
}

.link-underline:hover {
  background-size: 100% 3px;
  background-position: 0 100%
}

/* For mobile phones: */
@media only screen and (max-width: 768px) {

  .link-underline, .link-underline-yellow {
    transition: none;
    background-image: none;
  }

}