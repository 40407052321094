.menu-enter {
    max-height: 0;            
  }
  .menu-enter-active {
    max-height: 100vh;           
    transition: max-height 0.5s ;
  }
  
  .menu-exit {
    max-height: 100vh; 
  }
  
  .menu-exit-active {
    max-height: 0;   
    transition: max-height 0.5s ;
  }